var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('ti-card',{attrs:{"icon":_vm.$icons.star},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.isEdit ? 'Edit' : 'New'))]),_vm._v(" Podcast Awards: "+_vm._s(_vm.podcast_award.year))]},proxy:true},{key:"title-actions-shown",fn:function(){return undefined},proxy:true},{key:"title-actions-hidden",fn:function(){return [_c('ti-confirm',{attrs:{"message":"Are you sure you want to delete this document?","button-text":"Delete"},on:{"confirm":_vm.destroy}},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error","elevation":"2","fab":"","small":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.$icons.delete))])],1)],1)]},proxy:true}])},[_c('ti-form-layout',{attrs:{"title":"Winners"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.winners,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.product_url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.product.url,"target":"_blank"}},[_vm._v(_vm._s(item.product.url))])]}},{key:"item.nominations-dialog",fn:function(ref){
var item = ref.item;
return [_c('nominations-dialog',{attrs:{"nominations":item.nominations,"product-name":item.product.name}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.updateNominated(item.id)}}},[_vm._v(_vm._s(_vm.$icons.n))])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.updateShortListed(item.id)}}},[_vm._v(_vm._s(_vm.$icons.s))])],1)]}}])})],1)],1),_c('ti-form-layout',{attrs:{"title":"Short list"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.short_listed,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.product_url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.product.url,"target":"_blank"}},[_vm._v(_vm._s(item.product.url))])]}},{key:"item.nominations-dialog",fn:function(ref){
var item = ref.item;
return [_c('nominations-dialog',{attrs:{"nominations":item.nominations,"product-name":item.product.name}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.updateNominated(item.id)}}},[_vm._v(_vm._s(_vm.$icons.n))])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.updateWinner(item.id)}}},[_vm._v(_vm._s(_vm.$icons.w))])],1)]}}])})],1)],1),_c('ti-form-layout',{attrs:{"title":"Nominations"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.nominated,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.product_url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.product.url,"target":"_blank"}},[_vm._v(_vm._s(item.product.url))])]}},{key:"item.nominations-dialog",fn:function(ref){
var item = ref.item;
return [_c('nominations-dialog',{attrs:{"nominations":item.nominations,"product-name":item.product.name}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.updateShortListed(item.id)}}},[_vm._v(_vm._s(_vm.$icons.s))])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.updateWinner(item.id)}}},[_vm._v(_vm._s(_vm.$icons.w))])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }