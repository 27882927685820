<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.star">
			<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Podcast Awards: {{podcast_award.year}}</template>
			<template #title-actions-shown>
<!--				<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">-->
<!--					<v-icon>{{ $icons.save }}</v-icon>-->
<!--				</v-btn>-->
			</template>
			<template #title-actions-hidden>
				<ti-confirm @confirm="destroy" message="Are you sure you want to delete this document?" button-text="Delete">
					<v-btn color="error" elevation="2" fab small class="mr-4">
						<v-icon>{{ $icons.delete }}</v-icon>
					</v-btn>
				</ti-confirm>
			</template>
			<ti-form-layout title="Winners">
				<v-col cols="12">
					<v-data-table :headers="headers" :items="winners" item-key="name" :search="search">
						<template v-slot:top>
							<v-row justify="end">
								<v-col cols="12" lg="6">
									<v-text-field
										v-model="search"
										label="Search"
										prepend-icon="mdi-magnify"
										class="mx-4"
									></v-text-field>
								</v-col>
							</v-row>
						</template>
						<template v-slot:item.product_url="{ item }">
							<a :href="item.product.url" target="_blank">{{ item.product.url }}</a>
						</template>
						<template v-slot:item.nominations-dialog="{ item }">
							<nominations-dialog :nominations="item.nominations" :product-name="item.product.name"></nominations-dialog>
						</template>
						<template v-slot:item.actions="{ item }">
							<v-btn icon>
								<v-icon @click="updateNominated(item.id)">{{ $icons.n }}</v-icon>
							</v-btn>
							<v-btn icon>
								<v-icon @click="updateShortListed(item.id)">{{ $icons.s }}</v-icon>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</ti-form-layout>

			<ti-form-layout title="Short list">
				<v-col cols="12">
					<v-data-table :headers="headers" :items="short_listed" item-key="name" :search="search">
						<template v-slot:top>
							<v-row justify="end">
								<v-col cols="12" lg="6">
									<v-text-field
										v-model="search"
										label="Search"
										prepend-icon="mdi-magnify"
										class="mx-4"
									></v-text-field>
								</v-col>
							</v-row>
						</template>
						<template v-slot:item.product_url="{ item }">
							<a :href="item.product.url" target="_blank">{{ item.product.url }}</a>
						</template>
						<template v-slot:item.nominations-dialog="{ item }">
							<nominations-dialog :nominations="item.nominations" :product-name="item.product.name"></nominations-dialog>
						</template>
						<template v-slot:item.actions="{ item }">
							<v-btn icon>
								<v-icon @click="updateNominated(item.id)">{{ $icons.n }}</v-icon>
							</v-btn>
							<v-btn icon>
								<v-icon @click="updateWinner(item.id)">{{ $icons.w }}</v-icon>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</ti-form-layout>

			<ti-form-layout title="Nominations">
				<v-col cols="12">
					<v-data-table :headers="headers" :items="nominated" item-key="name" :search="search">
						<template v-slot:top>
							<v-row justify="end">
								<v-col cols="12" lg="6">
									<v-text-field
										v-model="search"
										label="Search"
										prepend-icon="mdi-magnify"
										class="mx-4"
									></v-text-field>
								</v-col>
							</v-row>
						</template>
						<template v-slot:item.product_url="{ item }">
							<a :href="item.product.url" target="_blank">{{ item.product.url }}</a>
						</template>
						<template v-slot:item.nominations-dialog="{ item }">
							<nominations-dialog :nominations="item.nominations" :product-name="item.product.name"></nominations-dialog>
						</template>
						<template v-slot:item.actions="{ item }">
							<v-btn icon>
								<v-icon @click="updateShortListed(item.id)">{{ $icons.s }}</v-icon>
							</v-btn>
							<v-btn icon>
								<v-icon @click="updateWinner(item.id)">{{ $icons.w }}</v-icon>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</ti-form-layout>
		</ti-card>
	</v-container>
</template>
<script>

import {call, sync} from "vuex-pathify";
import Models from "../../models";
import NominationsDialog from './components/NominationsDialog'

export default {
	name: "PodcastAwardsEdit",
	props: ['id'],
	components: {NominationsDialog},
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		podcast_award: sync('podcast_awards/podcast_award'),
		nominated() {
			return this.podcast_award.ranks?.filter((rank) => rank.status === 'Nominated')
		},
		short_listed() {
			return this.podcast_award.ranks?.filter((rank) => rank.status === 'Short listed')
		},
		winners() {
			return this.podcast_award.ranks?.filter((rank) => rank.status === 'Winner')
		}
	},
	data: () => ({
		search: '',
		headers: [
			{
				text: 'Product',
				align: 'start',
				sortable: true,
				value: 'product.name',
			},
			{
				text: 'Category',
				align: 'start',
				sortable: true,
				value: 'category',
			},
			{
				text: 'Product URL',
				align: 'start',
				sortable: true,
				value: 'product_url',
			},
			{
				text: 'Nominations',
				align: 'center',
				sortable: true,
				value: 'nominations-dialog',
			},
			{
				text: 'Votes',
				align: 'end',
				sortable: true,
				value: 'votes',
			},
			{
				text: 'Actions',
				align: 'end',
				sortable: true,
				value: 'actions',
			},
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		getPodcastAward: call('podcast_awards/getPodcastAward'),
		init() {
			if (this.isEdit) {
				this.getPodcastAward(this.id)
			} else {
				this.document = Models.PodcastAward()
			}
		},
		updateNominated(id) {
			this.updateStatus('Nominated', id)
		},
		updateShortListed(id) {
			this.updateStatus('Short listed', id)
		},
		updateWinner(id) {
			this.updateStatus('Winner', id)
		},
		updateStatus(status, id) {
			console.log('status: ', status);
			console.log('id: ', id);
			this.$api.podcast_award_ranks.update_status(id, status)
				.then(response => this.getPodcastAward(this.id))
				.catch(error => console.log(error))
		},
		save() {
		},
		editItem() {
		},
		destroy() {
			this.$api.documents.delete(this.id, this.document)
				.then(response => {
					this.$toast.add('Podcast award deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'PodcastAwards'})
				})
				.catch(error => {
					console.log(error.response);
					this.$toast.add(error.response.statusText, 'error')
				})
		},
	}
}
</script>